import React, { useContext } from "react";
import { PageContext } from "../../../../store/context/page-context";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import SVGGreenCheck from "../../../../assets/images/vector/components/Paywalls/greencheck.svg";
import SVGGrayCheck from "../../../../assets/images/vector/components/Paywalls/graycheck.svg";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const EnterpriseItem = ({
  name,
  info,
  isSelected,
  isNotAvailable,
  children,
}) => {
  const { translate: t, isIframeMode, isDeMode } = useContext(PageContext);
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);

  const { withFullProduct } = info;
  const isPageAndMobile = isNotInitMobile && !isIframeMode;
  const mobilePrefix = isPageAndMobile ? "Mobile " : "";
  const mobileIcon = (
    <img
      className="PgEP-EI-table__icon_green"
      src={SVGGreenCheck}
      alt="check icon"
    />
  );
  return (
    <div
      className={getTrueClasses(
        "PgEP-EI",
        isSelected && "PgEP-EI_selected",
        isNotAvailable && "PgEP-EI_disabled",
        !isIframeMode && "PgEP-EI_page",
        isDeMode && !isIframeMode && "PgEP-EI_de"
      )}
    >
      <p className="PgEP-EI__title">{t(name)}</p>
      <ul className="PgEP-EI-table">
        <li className="PgEP-EI-table__item">
          {isPageAndMobile && mobileIcon}
          {t(`${mobilePrefix}${name} Launches`)}
        </li>
        <li className="PgEP-EI-table__item">
          {isPageAndMobile && mobileIcon}
          {t(`${mobilePrefix}${name} Devices`)}
        </li>
        <li className="PgEP-EI-table__item">
          <img
            className={getTrueClasses(
              withFullProduct
                ? "PgEP-EI-table__icon_green"
                : "PgEP-EI-table__icon_gray"
            )}
            src={withFullProduct ? SVGGreenCheck : SVGGrayCheck}
            alt="check icon"
          />
          <span>
            {isPageAndMobile &&
              t("Full product and niche history data download")}
          </span>
        </li>
      </ul>
      {children}
    </div>
  );
};

EnterpriseItem.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
};

export default EnterpriseItem;
