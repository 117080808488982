import React, { useContext } from "react";
import "./index.scss";
import EnterpriseItem from "../EnterpriseItem";
import EnterprisePrice from "../EnterprisePrice";
import PropTypes from "prop-types";
import { PageContext } from "../../../../store/context/page-context";
import { getTrueClasses } from "../../../../helpers";

const EnterpriseTariffs = ({ prices, tariffs }) => {
  const { translate: t, isDeMode, isIframeMode } = useContext(PageContext);
  return (
    <section
      className={getTrueClasses("PgEP-ET", !isIframeMode && "PgEP-ET_page")}
    >
      <div
        className={getTrueClasses(
          "PgEP-ET-tariffs",
          isDeMode && "PgEP-ET-tariffs_de"
        )}
      >
        <ul className="PgEP-ET-list">
          <li className="PgEP-ET-list__item">{t("Launches per month")}</li>
          <li className="PgEP-ET-list__item">{t("Total devices")}</li>
          <li className="PgEP-ET-list__item">
            {t("Full product and niche history data download")}
          </li>
        </ul>
        {tariffs.map((tariff, index) => (
          <EnterpriseItem
            key={index}
            name={tariff.name}
            info={tariff.info}
            isSelected={tariff.isSelected}
            isNotAvailable={tariff.isNotAvailable}
          >
            {!isIframeMode && (
              <EnterprisePrice
                name={tariffs[index].name}
                isSelected={prices[index].isSelected}
                isNotAvailable={prices[index].isNotAvailable}
                isMobile
              />
            )}
          </EnterpriseItem>
        ))}
      </div>
      <div
        className={getTrueClasses(
          "PgEP-ET-prices",
          isDeMode && "PgEP-ET-prices_de"
        )}
      >
        <div />
        {prices.map((price, index) => (
          <EnterprisePrice
            name={tariffs[index].name}
            key={index}
            isSelected={price.isSelected}
            isNotAvailable={price.isNotAvailable}
          />
        ))}
      </div>
    </section>
  );
};

EnterpriseTariffs.propTypes = {
  prices: PropTypes.array.isRequired,
  tariffs: PropTypes.array.isRequired,
};

export default EnterpriseTariffs;
