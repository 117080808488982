import { useEffect, useState } from "react";
import { trackGa } from "../tracking/googleAnalytics";
import track from "../tracking/analytics";

const useEventFromExt = () => {
  const [software, setSoftware] = useState("");
  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const software = params.get("software");
    const category = params.get("ec");
    const action = params.get("ea");
    const label = params.get("el") || "";
    software && setSoftware(software);
    if (category && action && software) {
      trackGa(action, {
        category,
        label,
        transport_type: "beacon",
      });
      track(category, action, label, null, software);
    }
  }, []);
  return { software };
};

export default useEventFromExt;
