import React from "react";
export const ENTERPRISE_LIST = {
  SIX_HUNDRED_LAUNCHES: {
    INFO: "Info for six hundred launches",
    TITLE: "Title for six hundred launches",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  SIX_HUNDRED_FIFTEEN_LAUNCHES: {
    INFO: "Info for six hundred fifteen launches",
    TITLE: "Title for six hundred fifteen launches",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  SIX_THOUSAND_LAUNCHES: {
    INFO: "Info for six thousand launches",
    TITLE: "Title for six thousand launches",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: true,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: true,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  TEN_THOUSAND_LAUNCHES: {
    INFO: "Info for ten thousand launches",
    TITLE: "Title for ten thousand launches",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: true,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: true,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: true,
      },
      {
        isSelected: true,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  THREE_DEVICES: {
    INFO: "Info for three devices",
    TITLE: "Title for three devices",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  TEN_DEVICES: {
    INFO: "Info for ten devices",
    TITLE: "Title for ten devices",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: true,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: true,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  TWENTY_DEVICES: {
    INFO: "Info for twenty devices",
    TITLE: "Title for twenty devices",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: true,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: true,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: true,
      },
      {
        isSelected: true,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
  DOWNLOAD_DATA: {
    INFO: "Info for download data",
    TITLE: "Title for download data",
    TARIFFS: [
      {
        name: "Enterprise",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Premium",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
      {
        name: "Ultimate",
        info: {
          withFullProduct: true,
        },
        isSelected: false,
        isNotAvailable: false,
      },
    ],
    PRICES: [
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
      {
        isSelected: false,
        isNotAvailable: false,
      },
    ],
  },
};
