import React, { useContext } from "react";
import SVGOrangeCheck from "../../../../assets/images/vector/components/Paywalls/orangecheck.svg";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import TrackedLink from "../../../Analytics/TrackedLink";
import { PageContext } from "../../../../store/context/page-context";
import parse from "html-react-parser";
import { ExtensionController } from "../../../../controllers/common/extensionController";
import "./index.scss";

const EnterprisePrice = ({ name, isSelected, isNotAvailable, isMobile }) => {
  const {
    translate: t,
    isIframeMode,
    isJpMode,
    isDeMode,
    software,
  } = useContext(PageContext);
  const path =
    isIframeMode && software
      ? `${t(`${name} Url`)}&software=${software}`
      : t(`${name} Url`);
  return (
    <div
      className={getTrueClasses(
        "PgEP-EP",
        isNotAvailable && "PgEP-EP_disabled",
        !isIframeMode && "PgEP-EP_page",
        isJpMode && !isIframeMode && "PgEP-EP_jp",
        isMobile ? "PgEP-EP_mobile" : "PgEP-EP_desktop",
        isDeMode && !isIframeMode && "PgEP-EP_de"
      )}
    >
      <p className="PgEP-EP__title">
        {parse(t(`${name}${isSelected ? " Selected" : ""} Price`))}
      </p>
      {isSelected ? (
        <p className="PgEP-EP__select">
          <img src={SVGOrangeCheck} alt="icon" />
          {t("Your current plan")}
        </p>
      ) : (
        <TrackedLink
          handleClick={() =>
            ExtensionController.sendMessage({ event: "p.click" })
          }
          target
          path={path}
          action="BuyNow"
          category="EnterprisePaywall"
          class={getTrueClasses(
            "UI-Global-btn",
            "PgEP-EP__btn",
            isNotAvailable && "PgEP-EP__btn_gray"
          )}
        >
          {isNotAvailable ? t("Not available") : t("Buy now")}
        </TrackedLink>
      )}
      <p className="PgEP-EP__info">{t("10-day money-back guarantee")}</p>
    </div>
  );
};

EnterprisePrice.propTypes = {
  isSelected: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default EnterprisePrice;
