import React, { useContext } from "react";
import "./index.scss";
import EnterpriseTariffs from "./EnterpriseTariffs";
import PropTypes from "prop-types";
import { ENTERPRISE_LIST } from "./index.content";
import { PageContext } from "../../../store/context/page-context";
import { getTrueClasses } from "../../../helpers";
import parse from "html-react-parser";
import Container from "../../common/Container";

const Enterprise = ({ context }) => {
  const { translate: t, isDeMode, isIframeMode } = useContext(PageContext);
  const { INFO, TITLE, PRICES, TARIFFS } = ENTERPRISE_LIST[context.name];
  return (
    <section
      className={getTrueClasses(
        "PgEP-Enterprise",
        !isIframeMode && "PgEP-Enterprise_page",
        isDeMode && "PgEP-Enterprise_de"
      )}
    >
      {isIframeMode && (
        <>
          <p className="PgEP-Enterprise__title PgEP-Enterprise__space">
            {parse(t(INFO))}
          </p>
          <p className="PgEP-Enterprise__description PgEP-Enterprise__space">
            {parse(t(TITLE))}
          </p>
        </>
      )}
      {isIframeMode ? (
        <EnterpriseTariffs prices={PRICES} tariffs={TARIFFS} />
      ) : (
        <>
          <Container>
            <p className="PgEP-Enterprise__page-title">{t("Pricing plans")}</p>
          </Container>
          <EnterpriseTariffs prices={PRICES} tariffs={TARIFFS} />
        </>
      )}
      <div className="PgEP-Enterprise-help">
        <p className="PgEP-Enterprise__space PgEP-Enterprise-help__info">
          {parse(t("Help"))}
        </p>
      </div>
    </section>
  );
};

Enterprise.propTypes = {
  context: PropTypes.object.isRequired,
};

export default Enterprise;
